<template>
  <div>

    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
    >
      <FormProductPermissionsForm :i="default_permission_i" :values="values" :loaded_values="loaded_values" @create="createProductPermissionForm" @update="updateProductPermissionForm" @close="closeDialog" />
    </v-dialog>

    <v-row class="mt-0">
      <v-col cols="12">
        <div>
          <span class="primary--text">{{$t('USER_PERMISSION.TITLES')}}</span>
        </div>

        <div class="mb-4 mt-2 d-flex">
          <v-btn
            small
            rounded
            depressed
            class="mr-4"
            color="secondary black--text"
            @click="newProductPermission"
          >
            <v-icon size="16" class="mr-2">mdi-plus</v-icon>
            {{$t('COMMON.ADD')}}
          </v-btn>

        </div>
          <template v-if="values.default_permissions && values.default_permissions.length > 0">
            <v-card outlined>
              <v-list class="pa-0 scroll height-max-30vh position-relative">

                <Loading :value="loading" />

                  <template v-for="(item, i) in values.default_permissions">

                  <v-list-item
                    :key="`cont${i}`"
                    :class="[item.status === -1 ? 'muted' : '']"
                  >

                    <v-list-item-content>
                      <v-list-item-title>
                        {{item.name}}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.end_date || item.start_date">
                        <v-icon class="mr-2" size="16">mdi-calendar-clock</v-icon>
                        <small>
                          <strong class="mr-4" v-if="new Date(item.end_date) > new Date() && !isFinished(item)">{{ $t('USER_PERMISSION.WILL_FINISHED', {date: $moment(item.end_date).fromNow() }) }}</strong>
                          {{ new Date(item.start_date).toLocaleDateString() }}
                          <v-icon class="mr-2 ml-2" size="14">mdi-arrow-left-right</v-icon>
                          {{ new Date(item.end_date).toLocaleDateString() }}
                        </small>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.limit_value">
                        <small>
                          {{$t('USER_PERMISSION.LIMIT_VALUE', item.limit_value)}}
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action />

                    <v-list-item-action>
                      <div class="">
                        <v-btn icon @click="removeProductPermission(i)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon @click="editProductPermission(i)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                    </v-list-item>
                  <v-divider :key="`div${i}`" />
                </template>

              </v-list>
            </v-card>
          </template>
      </v-col>
    </v-row>
  </div>

</template>

<script>

import FormProductPermissionsForm from './FormProductCompact.permissions.form.vue'
import Loading from '@/components/Loading'


export default {
  name:"FormProductCompactPermissions",
  components: {
    FormProductPermissionsForm,
    Loading
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    default_permissions: [],
    default_permission_i: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
    loaded_values: {
      handler () {
      },
      deep: true,
    },

  },
  created () {
    this.attribValues()
  },
  methods: {

    isFinished (item) {
      let about_time = item.no_limit_time ? false : new Date(item.end_date) < new Date()
      let about_value = item.no_limit_value ? false : item.type === 'credit' ? item.commited >= item.value : item.used >= item.value
      return about_time && about_value
    },

    createProductPermissionForm(data) {
      if (data) {
        this.default_permissions.push(data)
        this.default_permission_i = this.default_permissions.length-1
        this.$emit('input', {default_permissions: this.default_permissions || []})
      }
    },

    updateProductPermissionForm(data) {
      if (data) {
        this.default_permissions[this.default_permission_i] = data
        this.$emit('input', {default_permissions: this.default_permissions || []})
      }
    },


    closeDialog () {
      this.dialog = false
    },

    newProductPermission() {
      this.default_permission_i = undefined
      this.dialog = true
    },

    removeProductPermission(i) {
      this.default_permission_i = undefined
      this.default_permissions.splice(i, 1)
      this.$emit('input', {default_permissions: this.default_permissions || []})
    },

    editProductPermission(i) {
      this.default_permission_i = i
      this.dialog = true
    },

    save_start_date(date) {
      this.$refs.menu_start_date.save(date)
    },

    save_end_date(date) {
      this.$refs.menu_end_date.save(date)
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    },


  }
}
</script>
